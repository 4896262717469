<template>
	<div>
		<!-- 新增提示 -->		
		<div class="remind" v-if="!message.IsOpen">
							<span style="margin-left:16px">订阅通知，当商城账户余额不足时，我们将第一时间通过短信给你发送提醒。</span>
						    <span class="co" @click="handclick">前往订阅</span>						
		</div>
		<div class="filter-top">
			
			<div style='width:50%;display:inline-block;'>
				<p>
					商城账户余额（元）
					<el-tooltip class="item" effect="dark" content="商城服务费、第三方同城配送费等费用产生将从账户余额中扣除。
					（其中服务费覆盖交易和直播过程中的流量、硬件、短信等费用）" placement="top-start" popper-class="topStart">
					     <i class="el-icon-warning-outline" style="font-size: 17px;color: #333;margin-left: 5px;"></i>
					</el-tooltip>
				</p>
				<div>
					{{accoutData.Balance}}
					<el-link type="primary" style="margin-left: 20px;margin-top: -3px;" @click="topup">充值
					</el-link>
					<span class="pay-help" @click="visible=true"><i class="el-icon-warning" style="font-size: 14px;color: #E6A23C;"></i><span>支付成功了确一直没到账？</span></span>
				</div>
			</div>
			<div style='display:inline-block;'>
				<p>免费额度（笔）</p>
				<p>{{accoutData.FreeOrderCount}}</p>
			</div>
		</div>
		<div class="filter-container" style="background: #fff;padding: 15px;">
			<el-tabs v-model="activeTabName" @tab-click="handleChangeTab">
				<el-tab-pane label="余额变更记录" name="1"></el-tab-pane>
				<el-tab-pane label="免费额度变更记录" name="2"></el-tab-pane>
			</el-tabs>
			<div class="filter-item">
				<label class="label">变更类型: </label>
				<el-select v-model="changeType" style="width: 150px;margin-right: 10px;">
					<el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">变更时间: </label>
				<el-date-picker v-model="TimeValue" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					:default-time="['00:00:00','23:59:59']">
				</el-date-picker>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:30px;width:90px;" @click="handleExport">导出</el-button>
			</div>
		</div>
		<div class="table-container" style="background: #fff;padding: 15px;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column key='1' prop="AddTimeStr" label="时间"></el-table-column>
				<el-table-column key='2' prop="RecordTypeName" label="变更类型"></el-table-column>
				<el-table-column key='3' v-if="activeTabName=='1'" prop="ChangeBalance" label="变更金额"></el-table-column>
				<el-table-column key='4' v-if="activeTabName=='1'" prop="AfterBalance" label="余额"></el-table-column>
				<el-table-column key='5' v-if="activeTabName=='2'" prop="ChangeFreeOrderCount" label="变更余额">
				</el-table-column>
				<el-table-column key='6' v-if="activeTabName=='2'" prop="AfterFreeOrderCount" label="余额">
				</el-table-column>
				<el-table-column key='7' prop="OrderNo" label="订单"></el-table-column>
				<el-table-column key='8' label="备注" width='370px'>
					<template slot-scope="scope">
						<el-tooltip class="item-tooltip" popper-class="myaccout-note-detail-tooltip topStart" effect="light"
							:content="scope.row.Remark" placement="top-start">
							<div class="note-detail">
								{{scope.row.Remark}}
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
				</el-pagination>
			</div>
		</div>


		<el-dialog title="余额充值" :visible.sync="rechargeVisible" width="600px" :before-close="rechargeClose" v-loading = "recharegeloading">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="充值金额" prop="money">
					<el-input v-model="ruleForm.money" :placeholder="'请输入充值金额, '+min+'起充'"></el-input>
				</el-form-item>
			</el-form>
			<el-checkbox style="margin-left:40px" v-model="knowrules">
				 <span style="color:#666666">阅读并同意 </span> 
				<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnof8DqVfrrlO8XoxpSPrTEc" target="_blank"
				 type="primary" :underline="false">{{recharegemsg.AgreementTitle}}</el-link>
			</el-checkbox>
			<span slot="footer" class="dialog-footer">
				<el-button @click="rechargeClose">取 消</el-button>
				<el-button type="primary" @click="handleRecharge">充 值</el-button>
			</span>
		</el-dialog>
		
		<el-dialog
		  title="提示"
		  :visible.sync="visible"
		  width="480px"
		  :before-close="kfClose">
		  
		  <div class="kf-dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">充值过程中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="kf-dialog-rwm" />
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="visible = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog
		  title="提示"
		  :visible.sync="failVisible"
		  width="480px">
		  
		  <div class="kf-dialog-tip">
			  <div class="icon">
				  <i class="el-icon-warning"></i>
			  </div>
			  <div class="txt">充值结果获取中，若遇到疑问，使用微信扫描下方二维码，<span style="color: #e7a43c;">添加专属客服</span>，一对一为你答疑解惑</div>
		  </div>
		  
		  <img src="@/assets/img/20210719171848.jpg" class="kf-dialog-rwm" />
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="failVisible = false" size="mini">我知道了</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog
		  title="提示"
		  :visible.sync="qrcodeVisible"
		  width="480px">
		  
			<div class="kf-dialog-tip" style="margin-bottom: 30px;">
				<div class="txt" style="text-align: center;">使用支付宝扫描下方二维码，完成支付</div>
			</div>
		  
			<!-- <img :src="qrcode" class="kf-dialog-rwm"></img> -->
			<div class="code" id="qrcode" style="width: 250px;margin: 0 auto;" ref="qrcode"></div>
		  
			<span slot="footer" class="dialog-footer">
				<el-button @click="payError" size="mini">支付失败</el-button>
				<el-button @click="getRechargeResult" size="mini" type="primary">我已支付成功</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index';
	import apiList from '@/api/other';
	
	import {
		mallBalanceApplyRecharge,
		mallBalanceRechargeResult,
		mallsystemAgreementInfoInit,
		mallNoticeSubscribeConfig//获取消息订阅信息
	} from '@/api/sv1.0.0.js'
	
	import QRCode from 'qrcodejs2'
	
	export default {
		data() {

			let checkMoney = (rule, value, callback) => {
				var arr = value.split('.');
				if (value === '' || value === '0') {
					callback(new Error('请输入充值金额'));
				} else if (isNaN(value) || value < 0){
					callback(new Error('请输入正确的充值金额'));
				} else if(arr[1] && arr[1].length > 2){
					callback(new Error('充值金额最多输入两位小数'));
				} else if (this.min && value < this.min) {
					callback(new Error('充值金额最低' + this.min + '元'));
				} else {
					callback();
				}
			};

			return {
				activeTabName: '1',
				changeType: null,
				changeTypeList: [{
						value: null,
						label: '全部'
					},
					{
						value: 3,
						label: '充值余额'
					},
					{
						value: 4,
						label: '扣减余额'
					},
				],
				TimeValue: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading: false,
				accoutData: {
					FreeOrderCount: 0,
					Balance: 0
				},

				//充值
				rechargeVisible: false,
				ruleForm: {
					money: ''
				},
				rules: {
					money: [{
						trigger: 'blur',
						validator: checkMoney,
					}]
				},
				min: 0,
				visible: false,
				failVisible: false,
				qrcode: '',
				qrcodeVisible: false,

				exportUrl: config.EXPORT_URL,


				knowrules:false,
				recharegeloading:false,
				recharegemsg:{},
				message:{}
			}
		},
		created() {
			this.getAccoutData()
			this.getList()	
			this.getMessageList()
		},
		methods: {
			handclick(){
				this.$router.push({
					path: '/setting/Newssubscription',
					// query:{
                    //   parameter:this.message
					// }
				})
			},
			//获取订阅消息
			async getMessageList(){
                try{
					let result_remind=await mallNoticeSubscribeConfig()
		            console.log(result_remind)
					this.message=result_remind.Result.find((i)=>{ return i.Type==3})	
				}
				finally{}
			},
			async topup(){
				this.rechargeVisible = true
				this.recharegeloading = true
				try{
					let res = await mallsystemAgreementInfoInit({
						AgreementType:0
					})
					if(res.IsSuccess){
						// console.log(res)
						this.recharegemsg = res.Result
					}
				}finally{
					this.recharegeloading = false
				}
			},
			handleChangeTab(tab) {
				this.currentPage = 1
				this.changeType = null
				this.TimeValue = []
				if (tab.name == 1) {
					this.changeTypeList = [{
							value: null,
							label: '全部'
						},
						{
							value: 3,
							label: '充值余额'
						},
						{
							value: 4,
							label: '扣减余额'
						},
					]
				} else {
					this.changeTypeList = [{
							value: null,
							label: '全部'
						},
						{
							value: 1,
							label: '赠送免费额度'
						},
						{
							value: 2,
							label: '扣减免费额度'
						}
					]
				}
				this.getList()
				this.$forceUpdate()
			},
			async getAccoutData() {
				let result = await apiList.mallBalanceInit()
				this.accoutData = result.Result;
				this.min = result.Result.MinRechargeMoney || 0;
			},
			//获取数据
			async getList() {
				this.loading = true
				try {
					this.TimeValue = (this.TimeValue == null) ? [] : this.TimeValue
					let data = {
						RecordPageType: this.activeTabName,
						RecordType: this.changeType,
						StartTime: this.TimeValue[0],
						EndTime: this.TimeValue[1],
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}

					let result = await apiList.mallBalanceIndex(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/mallBalance/export?' +
						'&RecordPageType=' + this.activeTabName +
						'&RecordType=' + (this.changeType ? this.changeType : '') +
						'&StartTime=' + (this.TimeValue[0] ? this.TimeValue[0] : '') +
						'&EndTime=' + (this.TimeValue[1] ? this.TimeValue[1] : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},

			//充值
			rechargeClose() {
				
				this.$refs['ruleForm'].resetFields();
				this.$refs['ruleForm'].clearValidate();
				this.rechargeVisible = false;
				this.knowrules = false
				
			},
			handleRecharge() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						if(this.knowrules){
							this.toRecharge();
						}else{
							this.$message.error(`请确认您已知晓并同意${this.recharegemsg.AgreementTitle}`)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async toRecharge(){
				try{
					const res = await mallBalanceApplyRecharge({
						RechargeMoney: this.ruleForm.money
					})
					
					if (res.IsSuccess){
						
						this.rechargeNo = res.Result.RechargeOrderNo;
						// window.open(res.Result.QrcodeUrl)
						this.qrcodeVisible = true;
						setTimeout(()=>{
							this.showQRCode(res.Result.QrcodeUrl)
						}, 500)
						
						
						this.rechargeClose();
						// this.$confirm('请在新窗口扫码完成支付', '提示', {
						// 	confirmButtonText: '我已支付成功',
						// 	cancelButtonText: '支付失败',
						// 	type: 'warning',
						// 	closeOnClickModal: false,
						// 	showClose: false
						// }).then(() => {
						// 	this.getRechargeResult()
						// }).catch(() => {
						// 	this.payError();
						// });
					}
				}catch(e){
					//TODO handle the exception
				}
				
			},
			payError(){
				this.qrcodeVisible = false;
				// this.$confirm('支付失败', '提示', {
				// 	confirmButtonText: '重新支付',
				// 	cancelButtonText: '联系客服',
				// 	type: 'warning',
				// 	closeOnClickModal: false,
				// 	showClose: false
				// }).then(() => {
				// 	this.toRecharge();
				// }).catch(() => {
					this.visible = true;
				// });
			},
			async getRechargeResult(){
				try{
					const res = await mallBalanceRechargeResult({
						RechargeOrderNo: this.rechargeNo
					})
					
					this.qrcodeVisible = false;
					if (res.Result.IsPay){
						this.$message.success('充值成功');
						this.getList();
						this.getAccoutData();
					}else{
						this.failVisible = true;
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			kfClose(){
				this.visible = false
			},
			showQRCode(url) {
				document.querySelector('#qrcode').innerHTML = '';
				
				this.qrcode = new QRCode('qrcode', {
					text: url,
					width: 250,
					height: 250,
				});
			},

		}
	}
</script>

<style lang="less" scoped>
.remind {
		width: 742px;
        height: 40px;
        background: #FBFDFF;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #409EEF;
		font-size: 14px;
		color: #606266  ;
		line-height: 40px;
		margin-bottom: 10px;
	}
.co{
	color:rgb(145, 204, 253);
	cursor: pointer;
}
	.filter-top {
		background: #fff;
		padding: 0 15px;
		width: 100%;
		margin-bottom: 10px;
	}

	.note-detail {
		width: 350px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
	
	.pay-help{
		font-size: 12px;
		display: inline-block;
		margin-left: 20px;
		cursor: pointer;
		color: #666;
		
		span{
			display: inline-block;
			vertical-align: 1px;
			margin-left: 3px;
			border-bottom: 1px solid #666;
		}
	}
</style>

<style lang="less">
	//不要加scoped！！！
	.myaccout-note-detail-tooltip {
		max-width: 350px !important;
	}
	
	.kf-dialog-tip{
		display: flex;
		
		.icon{
			flex: 0 0 auto;
			font-size: 16px;
			color: #e7a43c;
			line-height: 22px;
			margin-top: 1px;
			margin-right: 10px;
		}
		.txt{
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 22px;
		}
		
	}
	.kf-dialog-rwm{
		display: block;
		width: 300px;
		margin: 30px auto 0;
	}
	.topStart{
		max-width: 300px;
	}
</style>
